<template>
  <div class="bigbox">
    <!-- 大数据分析 -->
    <div class="zhfwtop">
       <div class="indeximgtopcontent">
           <div class="indexlogo"></div>
          <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span class="active2">产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <span  @click="go('/cpjjfa/zhcg')">智慧采购</span>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li class="active" @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
       </div>

      <div class="zhfwbutton"></div>
    </div>
    <!-- 产品全景架构 -->
    <div class="cpjg">
      <div class="cpjgcontent">
        <div class="cpjgcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">产品全景架构</div>
          <div class="hxystitleback2">
        </div>
        </div>
        <div class="cpjgpng"></div>
      </div>
    </div>
    <!-- 大数据体系搭建流程 -->
    <div class="dsjdjlc">
      <div class="dsjdjlccontent">
        <div class="dsjdjlccontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">大数据体系搭建流程</div>
          <div class="hxystitleback2">
        </div>
        </div>
        <div class="dslcpng"></div>
      </div>
    </div>
    <!-- 平台优势 -->
    <div class="ptys">
      <div class="ptyscontent">
        <div class="ptyscontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">产品全景架构</div>
          <div class="hxystitleback2">
        </div>
        </div>
        <div class="ptyspng"></div>
      </div>
    </div>
    <!--  -->
    <!-- <zi-xun></zi-xun> -->
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import ZiXun from '../../components/ZiXun.vue'
  export default {
  components: { ZiXun, TabarBottom },
    name:'dsj',
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }

  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
.dsjdjlc{
  // width: 1903px;
  height: 786px;
  margin: 0 auto;
  background: url('../.././assets/dsj_djlcback.png') no-repeat center;
  background-size: 100%;
  .dsjdjlccontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
      .dsjdjlccontenttitle{
  width: 584px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.dslcpng{
  width: 1200px;
  height: 476.69px;
  background: url('../.././assets/dsj_djlcpng.png') no-repeat center;
  background-size: 1200px 476px;
}
  }
}
// 平台优势
.ptys{
  // width: 1903px;
  height: 882px;
  margin: 0 auto;
  .ptyscontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    .ptyscontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.ptyspng{
  width: 1200px;
  height: 580px;
  background: url('../.././assets/dsj_ptyspng.png') no-repeat center;
  background-size: 1200px 580px;
}
  }
}
// 产品全景架构
.cpjg{
  // width: 1903px;
  height: 1206px;
  margin: 0 auto;
  .cpjgcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
     .cpjgcontenttitle{
  width: 463px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.cpjgpng{
  width: 1200px;
  height: 924px;
  background: url('../.././assets/dsj_cpjgback.png') no-repeat center;
  background-size: 1200px 924px;
}
  }
}
// 大数据分析
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/dsjindexback.png') no-repeat center 0;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
